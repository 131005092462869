<template>
    <div v-show="show">
        <div :class="['popup__overlay', { 'lang-en': this.$route.params.lang === 'en-US'}]">
            <div class="popup__container">
                <div class="popup__content">
                    <template v-if="showErrorPopup">
                        <div class="close" @click="$emit('close')"><svg-icon name="close" size="24" /></div>
                    </template>
                    <template v-else>
                        <router-link class="close" :to="'/' +this.$route.params.lang +'/home'" @click="switchOverflowScroll"><svg-icon name="close" size="24" /></router-link>
                    </template>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";

export default {
    props: {
        show: {
            type: Boolean,
        },
        showSuccessPopup: {
            type: Boolean,
        },
        showErrorPopup: {
            type: Boolean,
        },
    },
    methods: {
        switchOverflowScroll() {
            //鎖 body 捲動
            if (this.isPopupOpen == true) {
                $("html, body").css("overflow-y", "hidden");
            } else {
                $("body").css("overflow-y", "scroll");
                $("html").css("overflow-y", "inherit");
            }
        }
    }
}
</script>

<style lang="scss">
.popup__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: rgba(0,0,0, .3);
    padding: 14px;
    z-index: 9999;
    .popup__container {
        width: 100%;
        max-width: 1200px;
        margin: auto;
        .popup__content {
            background: #fff;
            border-radius: 12px;
            padding: 74px 30px;
            position: relative;
            .close {
                position: absolute;
                top: 30px;
                right: 30px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 576px) {
    .popup__overlay {
        align-items: start;
        .popup__container {
            .popup__content {
                padding: 40px 30px;
                .close {
                    top: 14px;
                    right: 14px;
                }
            }
        }
    }
    .lang-en .popup__overlay .popup__container .popup__content {
        font-size: 16px;
    }
}
</style>